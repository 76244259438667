import React from "react"
import { Container, Paper, Grid } from "@material-ui/core"

import SEO from "../components/seo"
import ContactUsSection from "../components/sections/ContactUsSection"
import Hero from "../components/sections/Hero"
import Navbar from "../components/sections/Navbar"

import { TextLeftImageRightBlock } from "../components/blocks/TextLeftImageRightBlock"
import { TextRightImageLeftBlock } from "../components/blocks/TextRightImageLeftBlock"
import { CenteredTextBlock } from "../components/blocks/CenteredTextBlock"

const TestPage = () => {
  return (
    <div className="basic-template page-{frontmatter.path}">
      <SEO title={"Title"} description={"Description"} />

      <Navbar />
      <Hero title="Test" />
      <Container size="lg" className="basic-template__content-wrapper">
        <Paper elevation={0}>
          <div className="basic-template__content-container">
            <CenteredTextBlock />
            <TextLeftImageRightBlock />
            <CenteredTextBlock />
            <TextRightImageLeftBlock />
            <CenteredTextBlock />
          </div>
        </Paper>
      </Container>
      <ContactUsSection />
    </div>
  )
}

export default TestPage
